@use "variables" as *;

// placeholders
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

//Buttons - Styles
@mixin primaryBtn($var: default) {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $base-white;
    background: $light-primary-color;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.725rem 1.5rem;
    font-weight: 500;
    text-decoration: none;
    @include trans;
    @include border-radius(50px);

    &:hover,
    &:active {
        color: $base-white;
        background: darken($primary-color, 10);
        text-decoration: none;
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        margin: 0 0 0 0.8rem;
        border-style: solid;
        border-width: 6.5px 0 6.5px 10px;
        border-color: transparent transparent transparent white;
    }
}

@mixin secondaryBtn($var: default) {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $base-black;
    background: $yellow-color;
    border: none;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.725rem 1.5rem;
    font-weight: 500;
    text-decoration: none;
    @include trans;
    @include border-radius(50px);

    &:hover,
    &:active {
        color: $font-color;
        background: darken($yellow-color, 10);
        text-decoration: none;
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        margin: 0 0 0 0.8rem;
        border-style: solid;
        border-width: 6.5px 0 6.5px 10px;
        border-color: transparent transparent transparent white;
    }
}

@mixin tertiaryBtn($var: default) {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $base-white;
    background: $base-black;
    border: none;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.725rem 1.5rem;
    font-weight: 500;
    text-decoration: none;
    @include trans;
    @include border-radius(50px);

    &:hover,
    &:active {
        color: $base-black;
        background: $primary-color;
        text-decoration: none;
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        margin: 0 0 0 0.8rem;
        border-style: solid;
        border-width: 6.5px 0 6.5px 10px;
        border-color: transparent transparent transparent white;
    }
}

@mixin fourthBtn($var: default) {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $base-white;
    background: transparent;
    border: 1px solid $base-white;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.725rem 1.5rem;
    font-weight: 500;
    text-decoration: none;
    @include trans;
    @include border-radius(50px);

    &:hover,
    &:active {
        color: $font-color;
        background: $base-white;
        text-decoration: none;
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        margin: 0 0 0 0.8rem;
        border-style: solid;
        border-width: 6.5px 0 6.5px 10px;
        border-color: transparent transparent transparent white;
    }
}

@mixin box_text {
    display: inline;
    padding: 5px 10px;
    margin: 0 0 5px;
    clear: both;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

//Border Radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

// Font icons
@mixin fa {
    display: inline-block;
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

@mixin fas($type: null) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;

    @if $type ==solid {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }

    @else if $type ==regular {
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
    }

    @else if $type ==brands {
        font-family: "Font Awesome 5 Brands";
    }

    @else {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
}

@mixin bi {
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// More
@mixin trans($transition: default, $pseudo: nope, $pseudo_trans: nope) {
    @if $transition ==default {
        $transition: all 0.3s ease;
    }

    @if $transition !=null {
        -webkit-transition: $transition;
        -moz-transition: $transition;
        transition: $transition;
    }

    @if $pseudo ==before or $pseudo ==after or $pseudo ==hover or $pseudo ==active or $pseudo ==focus or $pseudo ==target {
        &:#{$pseudo} {
            @if $pseudo_trans !=nope {
                $transition: $pseudo_trans;
            }

            -webkit-transition: $transition;
            -moz-transition: $transition;
            transition: $transition;
        }
    }
}

@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition: $what $time $how;
    -ms-transition: $what $time $how;
    -o-transition: $what $time $how;
    transition: $what $time $how;
}

@mixin box-shadow($top, $left, $blur, $size, $color) {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
}

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation ==vertical {
        background: -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=0);
    }

    @else if $orientation ==horizontal {
        background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    }

    @else {
        background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -o-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -ms-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    }
}

@mixin scrollbars($size, $border-radius, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $border-radius;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

@mixin center($xy: xy) {
    @if $xy ==xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        @include transform(translateX(-50%) translateY(-50%));
    }

    @else if $xy ==x {
        left: 50%;
        right: auto;
        @include transform(translateX(-50%));
    }

    @else if $xy ==y {
        top: 50%;
        bottom: auto;
        @include transform(translateY(-50%));
    }
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin flex($val: display) {
    @if $val ==display {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }

    @else {
        -moz-flex: $val;
        -webkit-flex: $val;
        flex: $val;
    }
}

@mixin justify-content($value: center) {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
    -ms-justify-content: $value;
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin flex-direction($value) {
    -ms-flex-direction: $value;
    -webkit-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-flow($value) {
    -ms-flex-flow: $value;
    -webkit-flex-flow: $value;
    flex-flow: $value;
}

@mixin flex-wrap($value: wrap) {
    -ms-flex-wrap: $value;
    -webkit-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin align-items($value: center) {
    -webkit-align-items: $value;
    align-items: $value;
}

@mixin align-content($value: center) {
    -webkit-align-content: $value;
    align-content: $value;
}

@mixin object-fit($val: cover) {
    @if $val ==contain {
        font-family: "object-fit: contain;";
        object-fit: contain;
    }

    @else {
        font-family: "object-fit: cover; object-position: 50% 50%;";
        object-fit: cover;
    }
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animate($name, $options: ease 1 normal) {
    -webkit-animation: $name $options;
    -o-animation: $name $options;
    animation: $name $options;
}

@mixin line-clamp($fontsize, $lineheight, $lines) {
    font-size: $fontsize;
    line-height: $lineheight !important;
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    /* IGNORELINE */
    height: $fontsize * $lineheight * $lines;
    /* Fallback for non-webkit */
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin lineclamp($lineheight, $lines) {
    line-height: $lineheight !important;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin content-column($column, $columnGap: 10px) {
    -webkit-column-count: $column;
    -moz-column-count: $column;
    column-count: $column;
    -webkit-column-gap: $columnGap;
    -moz-column-gap: $columnGap;
    column-gap: $columnGap;
}

// accepts: all, ie_only, edge_only
@mixin ie_media_query($target: all) {
    @if $target ==all or $target ==ie_only {

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            @content;
        }
    }

    @if $target ==all or $target ==edge_only {
        @supports (-ms-ime-align: auto) {
            @content;
        }
    }
}