@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .swiper-slide {
            height: auto;
        }

        .swiperArrowWrapper {
            right: 0;
            top: 0;

            button {
                background: transparent;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .card {
            padding: 5px 5px 0 5px;
            border-radius: 20px;
            background: $base-white;
            color: $font-color;
            border: 2px solid rgba(204, 204, 204, 1);
            @include border-radius(25px);

            a {
                &.dropdown-item {

                    &:hover,
                    &:focus,
                    &:active {
                        color: $font-color;
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    color: $font-color;

                    .post-title {
                        color: $primary-color;
                    }
                }


            }
        }


        img {
            border-radius: 15px 15px 0 0;
        }

        .post-description {
            @include line-clamp(14px, 1.2, 4);
        }

        .post-title {
            @include line-clamp(18px, 1.2, 2);
            @include trans;
        }

        .post-tag {
            top: 15px;
            margin: 0 15px;
        }

    }
}