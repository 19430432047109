@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        .left-column {
            @include border-radius(25px 25px 0 0);
            background: linear-gradient(180deg, $light-primary-color 0%, rgba(255, 204, 51, 0.00) 100%);
        }

        .right-column {
            background: linear-gradient(180deg, $yellow-color 0%, $yellow-color 0.01%, rgba(153, 204, 204, 0.00) 100%);
        }

        .card {
            a {
                border: 1px solid $base-black;
                background: transparent;
                @include trans;
            }

            .with-no-link {
                border: 1px solid $base-black;
                background: transparent;
                @include trans;
            }

            &:hover,
            &:focus,
            &:active {
                a {

                    background: $primary-color;
                    color: $base-white;
                    border-color: $primary-color;

                }
            }
        }

        @media (min-width: $breakpoint-md) {
            .left-column {
                @include border-radius(25px 0 0 0);
            }

            .right-column {
                @include border-radius(0 25px 0 0);
            }
        }
    }
}