// Fonts
$font-family: 'Inter', sans-serif;

// Colours
$primary-color: #14375A;
$secondary-color: #6C757D;
$tertiary-color: #99cccc;
$quarternary-color: #000000;
$yellow-color: #FFC300;

$light-primary-color: #006699;

$bg-light: #cccccc;
$bg-dark: #000000;
$bg-color: #ffffff;

$base-white: #ffffff;
$base-black: #000000;
$font-color: #000000;

$dark-gray: #313131;
// Links
$link-color: $primary-color;
$link-color-hover: darken($link-color, 5%);
$link-decoration: none;
$link-decoration-hover: none;

// Social
$linkedin: #0a66c2;
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #ff0000;
$instagram: #c32aa3;
$whatsapp: #25d366;

// Breakpoints
$breakpoint-xlg: 1929px;
$breakpoint-xxxl: 1599px;
$breakpoint-xxl: 1399px;
$breakpoint-xl: 1199px;
$breakpoint-lg: 991px;
$breakpoint-md: 767px;
$breakpoint-sm: 566px;